@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&family=Righteous&display=swap');

@font-face {
  font-family: "Aksara";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/AksaraSastrajendra.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-phone-icon {
  transition: transform 0.3s ease;
}

.navbar-phone-icon.open {
  transform: rotate(90deg);
}

.navbar-menu {
  transition: transform 0.8s ease;
}

.navbar-menu.open {
  transform: translateY(0);
}

.navbar-menu.closed {
  transform: translateY(-100%);
}